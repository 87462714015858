.contactPage.teklifAl .input{
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
}
.teklifAl .baslik6{
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.teklifAl .titles{
    margin-bottom: 4rem;
    
}
.teklifAl.contactPage .contactForm{
    background-color: #fff;
}



/* checkboxs */

.teklifAl .adsRow{
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5rem;
    display: grid;
}
.teklifAl .sCard{
    background-color: rgba(0,0,0,.05);
    box-shadow: none;
    padding: 10px 26px;
    grid-template-columns: 1fr 1.3fr;
}
.teklifAl .text2{
    color: black;
    font-weight: 600;
}
.teklifAl .baslik6{
    font-size: calc(3rem + 1vw);
}


/* checkbox */
.sCard{
    position: relative;
}

.checkbox {
    cursor: pointer;
    position: absolute;
    right: 31px;
    top: 16px;
  }
  
  .checkbox input {
    display: none;
  }
  
  .checkbox svg {
    overflow: visible;
  }
  
  .path {
    fill: none;
    stroke: var(--redColor);
    stroke-width: 6;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
    stroke-dasharray: 241 9999999;
    stroke-dashoffset: 0;
  }
  
  .checkbox input:checked ~ svg .path {
    stroke-dasharray: 70.5096664428711 9999999;
    stroke-dashoffset: -262.2723388671875;
    /* stroke: #fff; */
    
  }
  .checkbox input:checked  .teklifAl .sCard{
    background-color: var(--redColor);
  }
  /* checkbox */
  

 /* media queries  */
  
@media screen and (max-width:1024px) {
    
    .teklifAl .serviceCards{
        grid-template-columns: repeat(2,1fr); 
    }
    .checkbox {
        cursor: pointer;
        position: absolute;
        right: 12px;
        top:12px;
      }
}
@media screen and (max-width:768px) {
    .teklifAl .adsRow{
        display: flex;
        flex-direction: column;
    }
    .teklifAl .serviceCards{
        
        grid-template-columns: repeat(2,1fr);
        margin-right: 13px;
        margin-left: 13px;
    }
    .teklifAl .orn{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
}
@media screen and (max-width:448px) {
    
    .teklifAl .serviceCards{
        grid-template-columns: repeat(1,1fr);
    }
}

@media screen and (min-width:1024px) {
    .teklifAl .adsRow .orn{
        transform: translateX(50%);
    }
}
 /* media queries  */