
.phone .pne{
    position: fixed;
    transform: all .5s ease;
    box-shadow: 0 0 20px rgba(0, 0, 0, .15);
    border-right: none;
    color: #fff;
    line-height: 50px;
    z-index: 2;
    border: 0;
    font-weight: 700;
    right: 30px;
    bottom: 100px;
    width: 47px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #EF4557; */
    background-color: var(--blueColor);
    border-radius: 50%;
    transition: all .4s ease;
    
  }
  .phone ion-icon{
    color: white;
    font-size: 24px;
  }
  .phone .pne:hover{
    background-color: #454545;
  }

  .phone .pne{
    border: none;
    outline: none;
  }