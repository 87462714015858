.foot{
    background:#f9fafc;
    position: relative;
    width: 100%;
    height: auto;
    padding: 30px 0;
}

.foot .container{
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.foot .container .sec h2{
  position: relative;
  font-weight:600;
  margin-bottom: 15px;
}
.foot .container .quicklinks{
  position: relative;
 
}
.foot .container .quicklinks ul li a{
  margin-bottom: 10px;
  display: inline-block;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  
}
.foot .container .quicklinks ul li{
  overflow: hidden;
}
.foot .container .contact .info{
  position: relative;
}
.foot .container .contact .info li span{
  display: flex;
  grid-template-columns: 30px 1fr;
  margin-bottom: 16px;
  align-items: center;
  grid-gap: 12px;
}
.foot .container .contact .info i{
  font-size: 19px;
  color: var(--blueColor);
}
.foot .container .quicklinks a{
  position: relative;
  
}

/* hover kısmı */
.foot .container .quicklinks a:hover{
  
  transform: translateX(10px);
  
 
}
.foot .container .quicklinks a:hover::before{
  
  display: block;
  transition: 0.3s ease-in-out;
}

.foot .container .quicklinks a::before{
  content: " ";
  width: 10px;
  background-color: #000000;
  position: absolute;
  left: -15px;
  top: 50%;
  
  height: 2px;
  display: flex;
  align-items: center;
  transition: .2s;
}



/* hover kısmı */

.copyrightText{
  width: 100%;
  background: #f9fafc;
  padding: 20px 100px 40px;
  

}
.copyrightText .container{
  border-top: 1px solid rgba(69, 69, 69, 0.412);
  display: flex;
  justify-content: space-between;
}

@media (max-width:991px) {
  .foot{
    padding: 40px;
  }
  .foot .container{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap:20px
  }
  .copyrightText{
    padding: 20px 40px 30px;
  }
}
@media (max-width:768px) {
  .foot{
    padding: 40px;
  }
  .foot .container{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap:20px
  }
  .copyrightText{
    padding: 20px 40px 30px;
  }
  .copyrightText .container{
    border-top: 1px solid rgba(69, 69, 69, 0.412);
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.iletisim{
    
    display:flex;
    justify-content: space-around;
    align-items:center;
    padding: 30px;
    background: rgb(34,35,65);
    background: linear-gradient(117deg, rgba(34,35,65,1) 48%, rgba(69,72,129,1) 98%, rgba(68,71,129,1) 100%);
}

.footerHeader.baslik6{
    margin-bottom: 0;
    color: #000;
    letter-spacing: 2px;
    
}

.footIcon .icon{
    color: white;
    font-size: 20px;
    padding-right:4px ;
}
.iletisim .text2{
    font-size: 15px;
    font-weight: 600;
    color: #f9fafcce;
}

.iletisim .baslik6{
  color: #fff;
}

@media screen and (max-width:767px) {
    .iletisim{
        display: block;
    }
}

/* Button */

.button {
    cursor: pointer;
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    width: 70px;
    gap: 4px;
    animation: move 3s infinite ease;
  }
  
  .button:hover {
    animation: none;
  }
  
  @keyframes move {
    0% {
      transform: translateX(0);
    }
  
    50% {
      transform: translateX(20px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  .round {
    width: 6px;
    height: 6px;
    background: #f8f8f8;
    border-radius: 50%;
  }
  
  .line {
    display: flex;
    gap: 4px;
  }
  
  .two {
    transform: translateX(10px);
  }
  
  .three {
    transform: translateX(20px);
  }
  
  .four {
    transform: translateX(30px);
  }
  
  .five {
    transform: translateX(20px);
  }
  
  .six {
    transform: translateX(10px);
  }

  /* Button */
  @media screen and (max-width:500px) {
    .iletisim .baslik6{
      font-size: calc(2rem + 1vw);
    }
}
  @media screen and (max-width:425px) {
    .iletisim .baslik6{
      font-size: calc(1.8rem + 1vw);
    }
}
  @media screen and (max-width:375px) {
    .iletisim .baslik6{
      font-size: calc(1.6rem + 1vw);
    }
}
@media screen and (max-width:992px) {
  .foot .aboutus img{
    width: 250px;
    height: auto;
  }
}
@media screen and (max-width:600px) {
  .foot .aboutus img{
    width: 200px;
    height: auto;
  }
}