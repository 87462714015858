@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800;900&display=swap');

/* RESET */

/* scroll settings */
body::-webkit-scrollbar {
  width: 0.5em; 
}
/* output */
body::-webkit-scrollbar-thumb {
  
  
  
  background-image: -webkit-gradient(linear,
                     left bottom,
                     left top,
                     color-stop(0.44, rgb(107, 107, 107)),
                     color-stop(0.72, rgb(84, 84, 84)),
                     color-stop(0.86, rgb(39, 39, 39)));
}
body::-webkit-scrollbar-thumb:hover{
  opacity:1;
}
body::-webkit-scrollbar-track {
  
  background-color: transparent;
  
}

body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
 
}
/* selection */
::selection{
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standart yazım */
}

/* scroll settings */


*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family:'Montserrat',sans-serif ;
  
}
li { list-style: none; }
  
a {
  text-decoration: none;
  color: inherit;
}
span{
  display: inline;
}
a,
img,
time,
span,
input,
button,
ion-icon { display: block; }

img { height: auto; }

input,
button {
  background: none;
  border: none;
  font: inherit;
}

input { width: 100%; }

button { cursor: pointer; }

ion-icon { pointer-events: none; }

html {
  font-family: var(--ff-rubik);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--white);
  color: var(--onyx);
  font-size: 1.6rem;
  line-height: 1.7;
 
}
.flex{
  display: flex;
  align-items: center;
}
.grid{
  display: grid;
  align-items: center;
  gap: 1rem;
}
input{
  font-size: 100%;
}
.spanText{
  font-size: 14px;
}

.adsRow{
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
}
.btn{
  padding: 10px 10px;
  border: 2px solid var(--orangeColor);
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  color: var(--orangeColor);
  background: transparent;
  transition: ease 0.3s;
  z-index: 1;

}
.btn:hover{
  background-color: var(--orangeColor);
  color: white;
  letter-spacing: 1px;
}

.contentBtn{
  margin-top: 2.5rem;
  position: absolute;
  border-radius: 15px;
  padding: 10px;
  transition: all 0.3s ease;
  background-color:var(--blueColor);
  
  display: flex;
  align-items: center;
  color: white;
  width: 250px;
  justify-content: center;
  
}
.contentBtn:hover{
  letter-spacing: 1px;
  background-color: #4167e4;
  color: #fff;
  
}
.contentBtn.text{
  font-weight: 700;  
  
  
}
.contentBtn ion-icon{
  padding-left:10px;
  font-size: 19px;  
}



:focus-visible { outline-offset: 4px; }

::-webkit-scrollbar { width: 12px; }

::-webkit-scrollbar-track { background-color: var(--cultured-2); }

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
  border: 2px solid var(--cultured-2);
}

::-webkit-scrollbar-thumb:hover { background-color: hsl(0, 0%, 70%); }

/* ROOT */

:root{
  --grayColor:#454545;
  --redColor:#eb2f5b;
  --purpleColor:rgb(177 109 217);
  --lightOrangeColor:#FFA559;
  --titleColor:#454545;
  --blueColor:#2f5beb;
  --textGray:#797979;
  --lightBlueColor:#03a4ed;
  --plazaBlue:#222341;

}

/* Yazı Tipleri */
@font-face {
  font-family: 'sansBaslik';
  src: url('../public/fonts/MADE\ Outer\ Sans\ Black\ PERSONAL\ USE.ttf') format('truetype');
  font-display: swap;
 }
@font-face {
  font-family: 'sansMediumBaslik';
  src: url('../public/fonts/MADE\ Outer\ Sans\ Alt\ Medium\ PERSONAL\ USE.ttf') format('truetype');
  font-display: swap;
 }
@font-face {
  font-family: 'sansInceBaslik';
  src: url('../public/fonts/MADE\ Outer\ Sans\ Light\ PERSONAL\ USE.ttf') format('truetype');
  font-display: swap;
 }
@font-face {
  font-family: 'sansTextBaslik';
  src: url('../public/fonts/MADE\ Outer\ Sans\ Thin\ PERSONAL\ USE.ttf') format('truetype');
  font-display: swap;
 }

 .baslik{
  font-size: calc(1.6rem + 2vw);
  font-family: 'sansInceBaslik',sans-serif;
  font-weight: 500;
  
  
 }
 .baslik2{
  font-size: calc(2.5rem + 2vw);
  font-family: 'sansBaslik',sans-serif;
  font-weight: 600;

 }
 .baslik3{
  font-size: 4rem;
  font-family: 'Montserrat',sans-serif;
  font-weight: 600;
 }
 .baslik4{
  font-family: 'sansInceBaslik',sans-serif;
  font-size: calc(3.5rem + 1vw);
  letter-spacing: -.01em;
  line-height: 100%;
  margin-bottom: 5rem;
  color: rgba(0, 0, 0, 0.2);
  background: linear-gradient(to right, #ff00e1, #eb2f5b) no-repeat;
  background-clip: text;
  background-size: 0%;
  transition: background-size cubic-bezier(.1,.5,.5,1) 0.5s;
 }
 .baslik6{
  font-family: 'sansInceBaslik',sans-serif;
  font-size: calc(2.5rem + 1vw);
  letter-spacing: -.01em;
  line-height: 1.3;
  margin-bottom: 5rem;
  color: #454545;
  transition: background-size cubic-bezier(.1,.5,.5,1) 0.5s;
 }
 .baslik5{
  font-family: 'sansInceBaslik',sans-serif;
  font-size: calc(3.5rem + 1vw);
  letter-spacing: -.01em;
  line-height: 100%;
  margin-bottom: 5rem;
  color: rgba(173, 171, 171, 0.2);
  background: linear-gradient(to right, #ff00e1, #eb2f5b) no-repeat;
  background-clip: text;
  background-size: 0%;
  transition: background-size cubic-bezier(.1,.5,.5,1) 0.5s;
 }
 .baslik7{
  font-family: 'sansInceBaslik',sans-serif;
  font-size: 17px;
 }
 .baslik8{
  font-family: 'sansInceBaslik',sans-serif;
  padding-bottom: 15px;

 }
 .baslik9{
  font-size: 3rem;
  font-family: 'sansInceBaslik',sans-serif;
 
 }
 .baslik0{
  font-size: 18px;
  font-family: 'sansInceBaslik',sans-serif;
  font-weight: 800;
 }
 .cardBaslik{
  text-align: left;
  color: #03a4ed;
  font-size: clamp(2.2rem, 2.5vw, 3rem);
  font-weight: 800;
  opacity: 0.9;
 }

 @media screen and (max-width:990px) {
  .baslik3{
    font-size: 3rem;
  }
 }
 .baslikText{
  font-size: calc(0.8rem + 1vw);
  font-family: 'sansTextBaslik',sans-serif;
  /* color:rgb(177 109 217) ; */
  color: #eb2f5b;
  font-weight: 600;
 }
 .text{
  font-size: 17px;
  font-family:'Montserrat',sans-serif;
  text-align: center;
  color: #797979;

 }
 .text2{
  font-size: 19px;
  font-family:'Montserrat',sans-serif;
  text-align: left;
  color: #797979;

 }
 .text3{
  font-size: 16px;
  font-family:'Montserrat',sans-serif;
  text-align: left;
  padding-bottom: 3rem;

 }
 .text4{
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 11px;
 }
 .widgtText{
  font-size: 14px;
  font-family:'Montserrat',sans-serif;
  text-align: center;
  color: #797979;
  
  margin-top: 10px;
 }
 .space{
  margin-bottom: 3rem;
 }

 .container{padding-inline: 15px; padding-top: 6rem; overflow: hidden; }


/* banners */

/* shapeler */
.shape13{
  position: absolute;
  right: -266px;
  bottom: 265px;
  transform: rotate(90deg);
}
.shape16{
  position: absolute;
  height: 200px;
  right: 0;
}
/* shapeler */

 @media screen and (min-width:640px) {
  .container {
    max-width: 540px;
    width: 100%;
    margin-inline: auto;
  }
 }
 @media screen and (max-width:600px) {
  .container {
    max-width: 400px;
    width: auto;
    margin-inline: auto;
   
  }
  .baslik7{
    font-size: 11px;
   }
 }
 @media screen and (min-width:768px) {
  .container {
    max-width: 708px;
    width: 100%;
    margin-inline: auto;
  }
 }
 @media screen and (min-width:992px) {
  .container {
    max-width: 932px;
    width: 100%;
    margin-inline: auto;
  }
 }

 @media screen and (min-width:1024) {
  .container {
    max-width: 964px;
    width: 100%;
    margin-inline: auto;
  }
 }
 @media screen and (min-width:1200px) {
  .container {
    max-width: 1140px;
    width: 100%;
    margin-inline: auto;
  }
 }
 @media screen and (min-width:1440px) {
  .container {
    max-width: 1380px;
    width: 100%;
    margin-inline: auto;
  }
 }
