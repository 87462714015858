
.content .contentt{
    display: grid;
    grid-template-columns: 1fr 1fr;
    
}
.content{
   padding-top: 4rem;
   padding-bottom: 7rem;
   position: relative;
   background-color: #f9fafc;
}


.contentImg{
    display: flex;
    justify-content: end;
}
.contentImg img{
    width: auto;
    height: 500px;
}

.contentText .baslik4{
    line-height: 1.3;
    
}
.contentBtn{
    margin-top: 2.5rem;
    position: absolute;
    border-radius: 15px;
    padding: 10px;
    transition: all 0.3s ease;
    background-color:var(--blueColor);
    
    display: flex;
    align-items: center;
    color: white;
    width: 250px;
    justify-content: center;
    
  }
  .contentBtn:hover{
   
    letter-spacing: 1px;
    background-color: #4167e4;
    color: #fff;
    
  }
  .contentBtn.text{
    font-weight: 600;  
    
  }
  .contentBtn ion-icon{
    padding-left:10px;
    font-size: 19px;  
  }

.bubbles{
  position: relative;
  overflow: hidden;
  z-index: 0;
  margin-right: -28px;
  animation: mymove 20s infinite linear;
  

}
@keyframes mymove {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

  
@media screen and (max-width:1100px) {
    .content .contentt{
        display: flex;
        flex-direction: column-reverse;
        justify-items: end;
        align-items: center;
       
    }
    .contentImg img{
        height: 300px;
    }
    .content{
      padding-bottom: 13rem;
     
   }
   
}
@media screen and (max-width:600px) {
    .content .baslik6{
      font-size: calc(3rem + 1vw);
    }
  }
  @media screen and (max-width:400px) {
    .content .baslik6{
      font-size: calc(2.8rem + 1vw);
    }
  }