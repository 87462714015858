@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");

.horizontalScrollWrapper span{
    flex: 1;
    transform: translateX(calc(-50%*var(0.13)));
    transition: transform 1s cubic-bezier(.2,.6,.35,1);
}

.horizontalScrollWrapper{
    padding-top: 6rem;
    padding-bottom: 6rem;
    color: #6b6b6b;
    display: flex;
    flex-wrap: nowrap;
    font-size: 100px;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 140px;
    white-space: nowrap;
   
    
}
.horizontalScroll{
    max-width: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
   
}
.horizontalScrollWrapper strong{
    transition: all 0.4s ease;
}
@media screen and (max-width:1100px) {
    .horizontalScrollWrapper{
        display: none;
    }
}


.r2:hover{
   
    color:#484848;
}
.r3:hover{
   
    color:#484848;
}
.r4:hover{
   
    color:#484848;
}
.r5:hover{
   
    color:#484848;
}
.r6:hover{
   
    color:#484848;
}
.r7:hover{
   
    color:	#414141;
}


.horizontalScroll .htmlTag{
    font-size: clamp(0.1rem, 0.2rem + 3vw, 1.7rem);
    color:#454545;
    font-weight: 700;
    margin:0 20px 0 20px;
    display: inline;
    
    
}
.horizontalScroll .horizontal2{
    padding-top: 6rem;
    padding-bottom: 6rem;
    display: flex;
    max-width: 100%;
    position: relative;
    white-space: nowrap;
    letter-spacing: 1px;
    align-items: center;
    justify-content: center;
    width: 100%;
    
}
.strokeText{
    display: inline;

    font-weight: 700;
    font-size: clamp(0.3rem, 0.4rem + 4vw, 2.7rem);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 1);
    text-shadow: none;
    color:transparent;
    font-family: "Archivo Black", sans-serif;
}
.no-st-text{
    display: inline;
    font-weight: 700;
    font-size: clamp(0.3rem, 0.4rem + 4vw, 2.7rem);
    color: #454545;
}


.sliderContainer{
   
    top: calc(100vh-300px);
    
}
.slider{
    position: relative;
    white-space: nowrap;
    display: flex;
    
}
.slider p{
    color: #454545;
    margin: 0px;
    font-size: 10vw;
    font-weight: 500;
}