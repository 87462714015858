.contactForm{
    background-color: #f9fafc;
}
.adsRow{
    position: relative;
}
.contactHeader{
    padding: 16rem 11rem 11rem 11rem;
    background-color: #000;
 
 }
 .contactHeader iframe{
   
    height: 40vh;
    width: auto;
 }
 .contactTexts{
     color: #fff;
     display: flex;
     flex-direction: column;
     place-items: center;
     text-align: center;
 }
 /* inputlar */

.contactPage .input{
    position: relative;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 20px;
    padding-left: 20px;
    
}
.contactPage .messageInput{
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
   
}


.form-control::placeholder {
    color: transparent;
  }

  .form-control:placeholder-shown ~ .form__label {
    font-size: 1.4rem;
    cursor: text;
    top: 20px;
  }
  

.form-control:focus ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1.2rem;
    color: var(--blueColor);
    font-weight: 700;
  }

  .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1.2rem;
    color: #9b9b9b;
  }
  .form-control:focus {
    padding-bottom: 6px;
    border-width: 3px;
  }
  .form-control:required, .form-control:invalid {
    box-shadow: none;
  }

  
/* textarea */
.contactPage .form-control.messageControl{
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 33px 18px 33px 8px;
    resize: none;
    min-height: 230px;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    border: none;
    box-shadow: inset 0 -1px 0 0 #d9d9d9;
    border-radius: 0;
    margin-bottom: 40px;
    transition: all .3s linear;
}
.contactPage .form-control.messageControl:hover, .form-control.messageControl:active, .form-control.messageControl:focus{
    box-shadow: inset 0 -1px 0 0 var(--blueColor);
}
/* textarea */
.contactPage .form-control{
    border: none;
    border-bottom: 1px solid #d9d9d9;
    outline: none;
    height: 70px;
    padding: 10px;
    transition: all .3s linear
    
}
.form-control{
    margin-bottom: 40px;
}
.form-control:hover , .form-control:active, .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: var(--blueColor);
 }


 .contactHeader .contactContain{
     max-width: 700px;
     width: 100%;
     margin-inline: auto;
     
 }
 .contactPage .offer{
    margin-top: 0;
    background-color: #fff;
 }

@media screen and (max-width:767px) {
    .contactPage .input{
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        
    }
    .contactForm .contentBtn{
        position: relative;
        margin-bottom: 6rem;
        text-align: center;
        display: flex;
        justify-content: center;
    }
}

@media screen and (min-width:768px) {
    .adsRow .contentBtn{
        position: relative;
        margin-bottom: 6rem;
        text-align: center;
        display: flex;
        width: 280px;
        
    }   
    .adsRow .orn{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
    } 
}


/* harita kısmı */

.contact-section .container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 6rem;
    align-items: center;
}
.contact-section-info .contactText{
    display: grid;
    grid-template-columns: 0.4fr 4fr;
    align-items: center;
}
/* icon */

.contact-section-info .icons{
    font-size: 28px;
    color: #fff;
    background-color: var(--redColor);
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    transition: all .4s ease;
}
.contact-section-info .icons:hover{
    background-color: #454545;
}

/* icon */

.contact-section-info .text2{
    padding-left: 10px;
}


@media screen and (max-width:768px) {
    .contact-section .container{
        display: block;
        margin-bottom: 6rem;
        align-items: center;
    } 
    .contact-section-info{
        margin-bottom: 5rem;
    }
   
}
.notification {
    position: fixed;
    top: -100px; /* Bildirimi başlangıçta ekranın dışında tutuyoruz */
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
    background-color: #fff; /* Bildirim arka plan rengi */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Hafif bir gölgelendirme ekliyoruz */
    transition: top 0.3s ease-in-out; /* Bildirimin yukarı doğru hareketi için geçiş efekti */
}

.notification.success {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Bildirimi yukarıya hareket ettiriyoruz */
    background-color: #d4edda; /* Başarılı bildirim arka plan rengi */
}

.notification.error {
    top: 20px; /* Bildirimi yukarıya hareket ettiriyoruz */
    background-color: #f8d7da; /* Hata bildirimi arka plan rengi */
}

/* loading animate */
.dot-spinner {
    --uib-size: 2.8rem;
    --uib-speed: .9s;
    --uib-color: #ffffff;
    position: relative;
    
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
  }
  
  .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  
  .dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
  }
  
  .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
  }
  
  .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
  }
  
  .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
  }
  
  .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
  }
  
  .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
  }
  
  .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
  }
  
  .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
  }
  
  .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
  }
  
  .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
  }
  
  .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
  }
  
  .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
  }
  
  .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
  }
  
  .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
  }
  
  .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
  }
  
  @keyframes pulse0112 {
    0%,
    100% {
      transform: scale(0);
      opacity: 0.5;
    }
  
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }
.green {
    background-color: green;
}
.red{
    background-color: var(--redColor);
}