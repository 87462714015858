.home{
    height: 100vh;
    width: 100%;
    padding: 8rem 1.5rem;
    background: #000 ;
    background-position: top ;
    object-fit:cover ;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    row-gap: 3rem;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
   
}
.homeBackground img{
    width: 90%;
    height: 100%;
    transition: none 0s ease 0s;
    
    
}
.homebackg{
    position: absolute;
    display: block;
    overflow: visible;
    z-index: -1;
}
.homeBackground{
    
    z-index: 0;
    visibility: inherit;
    transition: none 0s ease 0s;
    overflow: visible;
    line-height: 0px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    left:100px;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}
.homeText{
    transform: translateY(-40%);
    color: #fff;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    text-align: center;
    justify-content: center;
    position: relative;
}
.homeButton{
  display: flex;
  justify-content: center; /* Yatayda ortalama */
  align-items: center; /* Dikeyde ortalama */
  position: absolute;
  right: 0; /* İhtiyaca bağlı olarak sağ kısmına yerleştirin */
  left: 0; /* İhtiyaca bağlı olarak sol kısmına yerleştirin */
  padding: 40px; 
}
.button{
    text-transform: uppercase;
    z-index: 14;
    white-space: nowrap;
    line-height: 17px;
    color: rgb(0, 0, 0);
    background-color: rgba(255, 255, 255, 0.75);
    border-color: rgb(0, 0, 0);
    border-radius: 30px;
    cursor: pointer;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 0px;
    margin: 0px;
    padding: 12px 35px;
    letter-spacing: 0px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}
/* .button.text{
    font-weight: 600;
    font-size: 17px;
} */

/* MEDİA QUERY */
@media screen and (min-width:860px) {
    .home{
        
        display: grid;
        padding: 0 10rem 0 10rem;
        justify-content: end;
        align-items: stretch;
    
        
    }
    .homeText{
        transform: translateY(-5%);
       
    }
}
@media screen and (min-width:1060px) {
    .home{
        
        display: grid;
        padding: 0 14rem 0 10rem;
        justify-content: end;
        align-items: stretch;
    
        
    }
    .homeText{
        transform: translateY(-5%);
       
    }
}

/* scroll btn */
.demo a {
    position: absolute;
    bottom: 20px;
    left: 50%;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: #fff;
    font : normal 400 20px/1 'Josefin Sans', sans-serif;
    letter-spacing: .1em;
    text-decoration: none;
    transition: opacity .3s;
  }
  .demo a:hover {
    opacity: .5;
  }
  .scrollbtn a {
    padding-top: 60px;
    
  }
  .scrollbtn a span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 30px;
    height: 50px;
    margin-left: -15px;
    border: 2px solid #fff;
    border-radius: 50px;
    box-sizing: border-box;
    
  }
  .scrollbtn a span::before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sdb10 2s infinite;
    animation: sdb10 2s infinite;
    box-sizing: border-box;
    z-index: 9999;
  }

/* kar */

canvas {
  display: block;
  background:radial-gradient(ellipse at top, #112 18%, #000 150%);
}



  @-webkit-keyframes sdb10 {
    0% {
      -webkit-transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      -webkit-transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes sdb10 {
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }