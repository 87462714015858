.services{
    background-color: #f9fafc;
    padding-bottom: 7em;
    padding-top: 4em;
   
}
.serviceCards{
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
   
    
}
.sCard{
    background-color: #ffffff;
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0 10px 18px 0 rgba(34,66,125,.08);
    margin-bottom: 20px;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    
}
.title{
    transition: all 0.4s ease;
}
.sCard:hover .title{
    color: var(--redColor);
}

@media screen and (max-width:1024px){
    .serviceCards{
    
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .sCard{
        
        align-items: center;
        display: flex;
        flex-direction: column;
        grid-template-columns: repeat(1,1fr);
        
    }
}
