.question .baslik6{
    text-align: center;
    margin-bottom: 1rem;
}
.question .text{
    font-size: 19px;
    
}
.question{
    margin-bottom: 7em;
}

.item{
    background: white;
    margin-bottom: 20px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 10px 18px 0 rgba(34,66,125,.08);
    border-left: 4px solid rgba(0,0,0,.1);
}
.title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}



.accordionIcon{
    padding: 0 1.5em;
    transition: all 0.5s;
}

.leftMenu li a{
    background-color: #fff;
    line-height: 64px;
    font-size: 18px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 0 0 0 24px;
    border-left: 4px solid rgba(0,0,0,.1);
    transition: all .2s linear;
    box-shadow: 0 10px 18px 0 rgba(34,66,125,.08);
   
}
.leftMenu li ul{
    display: none;
    padding-left: 24px;
    
}
.leftMenu li ul.open{
    display: block;
    padding: 24px 24px 24px 24px;
    box-shadow: 0 10px 18px 0 rgba(34,66,125,.08);
    background-color: #fff;
    border-radius: 0 0 6px 6px;
}

.leftMenu li a.active{
    background-color: #ee3b68;
    color: #fff;
    border-left-color: #ee3b68;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.leftMenu li:hover a{
    background-color: #ee3b68;
    color: #fff;
    border-left-color: #ee3b68;
}
.leftMenu .accordionIcon{
    font-size: 23px;
    transition: all 0.3s ease;
    
}
.leftMenu li a.active .accordionIcon{
    transform: rotate(90deg);
}
@media screen and (max-width:600px) {
    .question .baslik6{
      font-size: calc(3rem + 1vw);
    }
  }
  @media screen and (max-width:400px) {
    .question .baslik6{
      font-size: calc(2.8rem + 1vw);
    }
    .question .text{
        font-size: 14px;
    }
  }