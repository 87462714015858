
.jobs{
    
    /* align-items: center; */
    display: flex;
   
    flex-wrap: wrap;
    row-gap: 120px;
    column-gap: 20px;
    margin-bottom: 10rem;
    position: relative;
    transition: all .25s ease-out;
}
.jobs__card{
    width: 49%;
    background: rgba(156, 156, 156, 0.635);
    /* background-image: linear-gradient(
  320deg,
  hsla(226, 82%, 55%, 0.616) 0%,
  hsla(226, 73%, 60%, 0.616) 7%,
  hsla(226, 64%, 65%, 0.613) 15%,
  hsla(226, 56%, 70%, 0.612) 25%,
  hsla(226, 46%, 75%, 0.611) 36%,
  hsla(226, 36%, 79%, 0.61) 48%,
  hsla(227, 28%, 84%, 0.609) 61%,
  hsl(224deg 19% 89%) 74%,
  hsl(220deg 9% 94%) 88%,
  hsl(0deg 0% 98%) 100%
); */
    box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.18);
    backdrop-filter: blur(12px) saturate(167%);
    -webkit-backdrop-filter: blur(12px) saturate(167%);
    background-color: rgba(255, 255, 255, 0.26);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    border-radius: 7px;
    padding: 25px;
    padding-left: 35px;
    padding-right: 35px;
    position: relative;
    height: 300px;
}
.jobs__card img{
    max-width: 450px;
    width: 100%;
    height: auto;
    position: absolute;
    display: flex;
    top: 55%;
    left: 50%;
    transform: translate(-50%);
    transition: all .25s ease-out;
    scale: 1;
}

.jobs:hover  >  :not(:hover){
    backdrop-filter: blur(10px) saturate(167%);
    -webkit-backdrop-filter: blur(5px) saturate(167%);
    background-color: rgba(255, 255, 255, 0.16);
    transition: all .25s ease-out;
    
}
    
.jobs__card:hover img{
    top: 52%;
}
.jobs .text{
    margin-top: .8rem;
    text-align: left;
}





@media screen and (max-width:1199px) {
    .jobs__card{
        width: 48%;
    }
    .jobs__card img{
        top:62%;
    }
    .jobs__card:hover img{
        top: 58%;
    }
}
@media screen and (max-width:1000px) {
    .jobs__card{
        width: 100%;
    }
    
}


@media screen and (max-width:600px) {
   .jobs__card{
    height: 325px;
   }

}
@media screen and (max-width:350px) {
    .jobs__card img{
        top:70%;
    }
    .jobs__card:hover img{
        top: 66%;
    }
    
}

@media screen and (max-width:320px) {
    .jobs__card{
        height: 320px;
       }
    .jobs__card .cardBaslik{
        font-size: 2rem;
    }
    .jobs__card .text{
        font-size: 1.5rem;
    }
}


.jobs .baslik6{
    margin-bottom: 1rem;
   
}
.jobs .text2{
    margin-bottom: 5rem;
    
}


.jobs .slick-arrow.slick-prev {
    display: block !important;
    left: 3% !important;
    z-index: 1;
    border-radius: 0 !important;
    color: black;
    font-size: 20px;
  }
 .jobs .slick-arrow.slick-next {
    display: block !important;
    right: 3% !important;
    z-index: 1;
    color: black;
    border-radius: 0 !important;
    font-size: 20px;
    display: none;
    
  }
.jobs .slick-next,.slick-prev::before{
    color: black;
    content: none;
    display: none;
  }
.jobs .slick-next:before{
    content:none;
}
.jobs .slick-prev:before{
    color: black;
    content: none;
    display: none;
}


.örn{
   
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
/* button */
.btnn{
    margin-top: 2.5rem;
    position: absolute;
    border-radius: 10px;
    padding: 15px;
    transition: all 0.3s ease;
    background-color:var(--blueColor);
    display: flex;
    align-items: center;
    color: #fff;
    box-shadow: 0 5px 14px 0 rgba(18, 27, 44, 0.289);
    font-weight: 700;
    padding-left: 25px;  
    padding-right: 25px;  
    
  }
  .btnn:hover{
    letter-spacing: 1px;
    background-color: #4167e4;
    color: #fff;
   
  }
  
  .btnn.text{
    font-weight: 600;  
  }
  .btnn i{
    padding-left:10px;
    font-size: 19px;  
  }
  