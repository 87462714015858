/* menu animation */
.menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    z-index: 99999;
    
  }
  .opened .line{
    stroke: aliceblue;
    transition: all 0.4s;
   
  }
  .line {
    fill: none;
    stroke: rgb(255, 255, 255);
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
     
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
    stroke-linecap: round;
    
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
    stroke-linecap: round;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
    stroke-linecap: round;
  }
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
    stroke-linecap: round;
  }
  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
    stroke-linecap: round;
  }
  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
    stroke-linecap: round;
    
  }