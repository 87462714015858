.navHeader{
   padding: 16rem 11rem 11rem 11rem;
   background-color: #000;

}
.texts{
    color: #fff;
    display: flex;
    flex-direction: column;
    place-items: center;
    text-align: center;
}
.navHeader .contain{
    max-width: 700px;
    width: 100%;
    margin-inline: auto;
    
}