header{
    position: fixed;
    align-items: center;
    padding: 2rem 1.5rem;
    color: #fff;
    width: 100%;
    z-index: 999;
    transition: all 300ms ease-in-out;
    
  }

.menuLists{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.menuItem .menuLogo{
    display: block;
}
.mobile{
    display: none;
}


/* MEDİA QUERY */

@media screen and (max-width:768px){
    .mobile{
        display: flex;
    }
    .menuNav{
        display: none;
        
    }
    .nav{
        position: fixed;
        z-index: -2;
       
    }
    .navbar{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em;
        transition: all .6s;
        
    }
    .nav-overlay{
        position: fixed;
        background: #18181c;
        z-index: -2;
        top: -100%;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        padding: 4em;
        transition: all 1s cubic-bezier(0.16,1,0.3,1);
    }
    
    .logo img{
        
        height: auto;
        max-width: 70%;
    }
    ul.nav-links{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    ul.nav-links li{
        list-style: none;
        position: relative;
    }
    ul.nav-links li a{
        color: #fff;
        font-size: 2.5em;
        position: relative;
        top: 0;
        transition: all 2s cubic-bezier(0.16,1,0.3,1);
    }
    .nav-item-wrapper::after{
        content: '';
        position: absolute;
        top: 120px;
        left: -10%;
        width: 120%;
        height: 100%;
        background: #18181c;
        margin: 0 auto;
    }
    .nav-footer{
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 2em;
        display: flex;
        justify-content: space-between;
    }
    .nav-social-media ul{
        display: flex;
    }
    .nav-social-media ul li a{
        position: relative;
        bottom: -20px;
        text-transform: uppercase;
        padding-left: 2em;
        opacity: 0;
        transition: all 2s cubic-bezier(0.16,1,0.3,1);
    }
    .location{
        position: relative;
        bottom: -20px;
        opacity: 0;
        transition: all 2s cubic-bezier(0.16,1,0.3,1);
        text-transform: uppercase;
    }
    .scroll-down .navbar {
       
        transform: translate3d(0, -100%, 0);
        padding: 1rem;
      }
    .scroll-up .navbar{
        background: #18181c;
        padding: 1rem;
    }
    
}
@media screen and (min-width:769px){
    .menuNav{
        display: flex;
        flex-direction: row;
        padding: 1rem .5rem;
        align-items: center;
        justify-content: center;
        
    }
    .menuItem.text{
        color: rgb(255, 255, 255);
        font-weight: 600;
        transition: all .5s ease;
    }
    .menuNav .menuItem{
        
        font-size: 15px;
        font-weight: 600;
        padding: 0 2rem;
        transition: all .5s ease;
      }

    /* button silme */
    button.menu{
        display: none;
        stroke: #fff;
    }
     /* button silme */
     .scroll-down header {
        transform: translate3d(0, -100%, 0);
      }
      
      .scroll-up header {
        background-color: #000000d7;
        padding: 1rem 0;
      }
      .scroll-up .menuNav .menuItem{
        padding:  0 36px;
      }
}
@media screen and (min-width:1440px){
    .menuNav .menuItem{
        font-size: 16px;
        padding: 0 4rem;
      }


}


 

