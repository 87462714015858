.offer{
    margin-top: 16rem;
    padding-top: 7rem;
    padding-bottom: 6rem;
    background-color: #f9fafc;
}
.offer .text2{
    font-size: 22px;
}
.offer .baslik6{
    margin-bottom: 1rem;
}
.offer .container{
    padding:  0 26rem 0 0;
}


@media screen and (max-width:1024px) {
    .offer .container{
        padding: 0 22rem 0 0;
    }
}
@media screen and (max-width:768px) {
    .offer .container{
        padding: 0 0 0 0;
        padding-inline: 15px;
    }
    .offer .contentBtn{
        position: relative;
        display: flex;
        text-align: center;
        justify-content: center;
        width: 100%;
        
    }   
    .offer .orn{
        display: flex;
        justify-content: center;
    } 
}
@media screen and (max-width:600px) {
    .offer .container{
        padding: 0 0 0 0;
        padding-inline: 15px;
    }
}
@media screen and (max-width:600px) {
    .offer .baslik6{
      font-size: calc(3rem + 1vw);
    }
  }
  @media screen and (max-width:400px) {
    .offer .baslik6{
      font-size: calc(2.8rem + 1vw);
    }
  }
  @media screen and (min-width:768px) {
    .offer .contentBtn{
        position: relative;
        text-align: center;
        justify-content: center;
        display: flex;
        width: 250px;
        
    }   
    
}
