.card{
  background-color: rgb(255, 255, 255);
  height: 450px;
  width: 100%;
  box-shadow:0 10px 30px -5px rgba(0,0,0,.1);
  padding: 15px;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
}
.card:hover h3{
  color: var(--redColor);
  scale: 1.1;
  
}



.back{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all .8s cubic-bezier(.25,.1,.11,1.1);
  transform-style: preserve-3d;
  perspective: 1000px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateY(-180deg);
}
.back:before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjkuMDIgMTI0LjA2Ij48ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIj48ZyBpZD0iTGF5ZXJfMS0yIiBkYXRhLW5hbWU9IkxheWVyIDEiPjxwYXRoIGlkPSJQb2x5Z29uXzQiIGRhdGEtbmFtZT0iUG9seWdvbiA0IiBkPSJNNTIuOCwzLjc5YTIwLDIwLDAsMCwxLDIzLjQyLDBMMTIwLjczLDM2QTIwLDIwLDAsMCwxLDEyOCw1OC4zN2wtMTcsNTEuOWEyMCwyMCwwLDAsMS0xOSwxMy43OUgzN2EyMCwyMCwwLDAsMS0xOS0xMy43OUwxLDU4LjM3QTIwLDIwLDAsMCwxLDguMjksMzZaIi8+PC9nPjwvZz48L3N2Zz4=);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  
  filter: invert(32%) sepia(41%) saturate(3808%) hue-rotate(326deg) brightness(91%) contrast(102%);
  
}
.card:hover .front {
  transform: rotateY(180deg);
}

.card:hover .back {
  transform: rotateY(0deg);
}
.back .icon{
  color: white;
}
.back .iconn{
  stroke: rgb(255, 255, 255);
  
}

h3{
  margin-top: 30px;
  margin-bottom: 25px;
  font-weight: 600;
  color: #131430;
  text-align: center;
  transition: all 0.2s ease-in-out;
}
.front{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 1s cubic-bezier(.25,.1,.11,1.1);
  transform-style: preserve-3d;
  perspective: 1000px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.front::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjkuMDIgMTI0LjA2Ij48ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIj48ZyBpZD0iTGF5ZXJfMS0yIiBkYXRhLW5hbWU9IkxheWVyIDEiPjxwYXRoIGlkPSJQb2x5Z29uXzQiIGRhdGEtbmFtZT0iUG9seWdvbiA0IiBkPSJNNTIuOCwzLjc5YTIwLDIwLDAsMCwxLDIzLjQyLDBMMTIwLjczLDM2QTIwLDIwLDAsMCwxLDEyOCw1OC4zN2wtMTcsNTEuOWEyMCwyMCwwLDAsMS0xOSwxMy43OUgzN2EyMCwyMCwwLDAsMS0xOS0xMy43OUwxLDU4LjM3QTIwLDIwLDAsMCwxLDguMjksMzZaIi8+PC9nPjwvZz48L3N2Zz4=);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  filter: invert(98%) sepia(1%) saturate(188%) hue-rotate(97deg) brightness(112%) contrast(92%);
}
.cardIcon{
  position: relative;
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card .inner{
  display: flex;
  align-items: center;
  flex-direction: column;
  transform-style: preserve-3d;
  perspective: 1000px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(100px) scale(.8);
  
}
.icon{
 font-size: 45px;
 color: #4f4f4f;
 display: flex;
 justify-content: center;
 align-items: center;
  z-index: 99;
}
.iconn{
  fill: none;
  stroke: #4f4f4f; color: #4f4f4f;
  z-index: 99;
  display: flex;
  border: none;
  width: 60px;
}

.cards .slick-list {
  margin: 0 -15px !important;
  
}

.cards .slick-slide > div {
  padding-right:20px !important;
  padding-left:20px !important;
  
  
}
.cards .slick-dots li.slick-active button:before{
  scale: 1.5;
  color: var(--blueColor)!important;
  
}
.cards .slick-dots{
  position: static !important;
}
.jobs .slick-arrow{
  display: none !important;
}
@media screen and (max-width:600px) {
  .cards .baslik6{
    font-size: calc(3rem + 1vw);
  }
}
@media screen and (max-width:400px) {
  .cards .baslik6{
    font-size: calc(2.8rem + 1vw);
  }
}