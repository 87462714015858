.Asamalar{
    background: rgb(34,35,65);
    background: linear-gradient(117deg, rgba(34,35,65,1) 48%, rgba(69,72,129,1) 98%, rgba(68,71,129,1) 100%);
    margin-top: 6rem;
    padding-bottom: 7rem;
    position: relative;
}
.widget{
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.widget .title{
    margin: 0em 0em 0.35em 0em;
}
.Asamalar h2{
    color: #ddd;
}

.widget p{
    color: #ddd;
}
/* img */

.widgtImg{
    margin: 40px 0 30px 0;
    justify-content: center;
    display: flex;
    
}
.shadow-img{
    
    box-shadow: 0 13px 15px rgba(93, 92, 92, 0.15);
    border-radius: 6px;
}

/* img */

@media screen and (min-width:768px) {
    .widgets{
        
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: flex-end;

    }
    .widget{
        padding-right: 30px;
    }
   
}
@media screen and (max-width:600px) {
    .Asamalar .baslik6{
      font-size: calc(3rem + 1vw);
    }
  }
  @media screen and (max-width:400px) {
    .Asamalar .baslik6{
      font-size: calc(2.8rem + 1vw);
    }
  }

