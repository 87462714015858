.jobList{
    margin-bottom: 2rem;
}
.webTasarim{
    background-color: #f9fafc;
    padding-bottom: 7em;
    padding-top: 4em;
}
.webTasarimlists{
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 0px;
}

.webTasarim .icerik{
    box-shadow: 0 10px 18px 0 rgba(34,66,125,.08);
    width:100%;
    height:auto;
    background-color:#fff;
    margin-bottom:2.5rem;
    border-radius: 7px;
    padding: 40px 56px 96px;
    position: relative;
}

.icerik .baslik9{
    margin-top: 2rem;
    margin-bottom: 2rem;

}
.icerik ul li{
    background: url('../../../../public/img/li.svg');
    background-repeat: no-repeat;
    background-position: left;
    padding-left: 56px;
    margin-bottom: 50px;
    align-items: center;
    display: flex;
}
.imageList{
    display: flex;
    flex-wrap: wrap;
}
.imageList div{
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 20px;

}

/* product */

.product .contentBtn{
    background-color: #f9fafc;
    border: 2px solid #f9fafc;
    color: var(--redColor);
    padding-right: 20px;
    padding-left: 20px;
    position: static;
    z-index: 999;
   
}
.product .contentBtn:hover{
    background-color: transparent;
    color: #f9fafc;
}
.product .productDetails{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.product .productDetails .text2{
    font-weight: 800;
    font-size: 32px;
    color: #fff;
  
}
.product .productDetails{
    background: url('../../Assets/productBanner.webp');
    position: relative;
    padding: 60px 50px;
    background-size: cover;
    box-shadow: 0 10px 18px rgba(34,66,125,.08);
    border-radius: 10px;
    background-position-y: 39%;
    margin-bottom: 16px;
}
.product .productDetails::before{
    position: absolute;
    content: "";
    background: linear-gradient(100.53deg,rgba(36,47,57,.43) 32.28%,rgba(255,90,90,.36) 83.35%);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: -1;
}


.services .leftMenu li a.actived{
    background-color: #ee3b68;
    color: #fff;
    border-left-color: #ee3b68;
}
.resp-d-none{
    display: none;
}

/* media queries */
@media screen and (max-width:1200px){
    .product .productDetails .text2{
        font-size: 27px;

    }
    .product .contentBtn.text{
        font-size: 14px;
       
    }
}

@media screen and (max-width:992px) {
    .d-none{
        display: none;
    }
    .webTasarimlists{
        display: inline-block;
        
    }
    .services .leftMenu li a.actived .accordionIcon{
        transform: rotate(90deg);
    }
    .resp-d-none{
        display: block;
    }
    .product .productDetails{
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: stretch;
        
    }
   .product .text2{
        text-align: center;
   }
  
}

@media screen and (max-width:767px){
    .icerik .imageList img{
        width: auto;
        
        height: 50px;
    }
   
}
@media screen and (max-width:600px) {
    .imageList div{
        flex: 0 0 50%;
        max-width: 50%;
        padding: 20px;
    
    } 
    .webTasarim .icerik{
      padding: 40px 50px 96px;
        
    }
}
@media screen and (max-width:378px) {
    .webTasarim .icerik{
        padding: 40px 26px 96px;
          
      }
}
@media screen and (max-width:320px) {
    .webTasarim .icerik{
        padding: 40px 16px 96px;
          
      }
}